/** @format */

// styles
import "../styles/index.scss";

// Flickity
// var Flickity = require("flickity");

// UIKit
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";

// loads the Icon plugin
UIkit.use(Icons);

// components can be called from the imported UIkit reference
// UIkit.scroll();

// UIkit
// import UIkit from './uikit';

// const app = () => {
// 	// Make UIkit accessible via browser console
// 	global.UIkit = UIkit;

// 	// Register components
// 	// UIkit.component('example', Example);
// };

// // Load polyfills first, then start the application
// Promise.all(app);

async function init() {
	// import all your functions/code there
	UIkit.scroll();

	// var flkty = new Flickity( '.carousel', {

	// });
}

if (document.readyState === "complete") {
	init();
} else {
	window.addEventListener("load", init);
}

// console.log("webpack starterkit");
